import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import blogStyle from "../css/blog.css";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the directory you specified for the
          "gatsby-source-filesystem" plugin in gatsby-config.js).
        </p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <BlogLayout>
        <div className="max-w-xl">
          <ol style={{ listStyle: `none` }}>
            {posts.map((post) => {
              const title = post.frontmatter.title || post.fields.slug;

              return (
                <li key={post.fields.slug}>
                  <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                    <header>
                      <h2>
                        <Link to={`/${post.fields.collection}${post.fields.slug}`} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h2>
                      {/* <small>{post.frontmatter.date}</small> */}
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </li>
              );
            })}
          </ol>
        </div>
      </BlogLayout>
    </Layout>
  );
};

const BlogLayout = ({ children }) => {
  return <div className="blog px-8 flex flex-col items-center mt-10">{children}</div>;
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      nodes {
        excerpt
        fields {
          collection
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
